<template>
  <div class="signin">
    <el-row type="flex" justify="center">
      <el-col :xs="20" :sm="12" :md="8" :lg="8" :xl="4" class="signin-content">
        <router-link to="/">
          <img class="signin-logo" alt="logo" src="/logo.png" height="60" />
        </router-link>
        <el-card class="signin-card" shadow="hover" v-loading="loading">
          <div slot="header" class="signin-title">
            <span>{{ $t('signin.title') }}</span>
            <el-dropdown @command="handleCommand" style="float: right">
              <span class="el-dropdown-link">
                {{ siteIntl ? "International" : "中国站" }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="cn">{{ "中国站" }}</el-dropdown-item>
                <el-dropdown-item command="kr">{{ "International" }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-form ref="form" :model="form" :rules="signinRules">
            <el-form-item prop="email">
              <el-input v-model="form.email" size="small" :placeholder="$t('signin.emailUsername')"
                prefix-icon="el-icon-user" autocomplete="on" tabindex="1" @keyup.enter.native="onSubmit"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="form.password" size="small" :placeholder="$t('common.password')"
                prefix-icon="el-icon-lock" show-password tabindex="2" @keyup.enter.native="onSubmit"></el-input>
            </el-form-item>
            <el-form-item>
              <div class="find-password">
                <el-link type="info">{{ $t('signin.forgot') }}</el-link>
              </div>
              <el-button class="signin-button" type="primary" size="small" @click.native.prevent="onSubmit">{{
        $t('common.signin') }}</el-button>
            </el-form-item>
            <el-form-item class="signin-signup">
              <span class="signin-info">{{ $t('signin.signupGuide') }}</span>
              <router-link to="/signup">
                <el-link type="primary">{{ $t('signin.signupLink') }}</el-link>
              </router-link>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <ai-footer />
  </div>
</template>


<script>
import AiFooter from "@/components/AiFooter.vue";
import { validUsername } from "@/utils/validate";

export default {
  name: "Signin",
  components: {
    AiFooter
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error(this.$t('signin.emailRequired')));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error(this.$t('signin.passwordRequired')));
      } else {
        callback();
      }
    };
    return {
      signinRules: {
        email: [
          { required: true, trigger: "blur", validator: validateUsername }
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword }
        ]
      },
      form: {
        email: "",
        password: ""
      },
      redirect: undefined,
      otherQuery: {},
      loading: false
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/signin", this.form)
            .then((redirect) => {
              this.loading = false;
              if (redirect) {
                this.$alert(this.$t("signin.redirectMsg", { code: this.$t("signin." + redirect.code) }),
                  this.$t("signin.redirectTitle"), {
                  confirmButtonText: this.$t("signin.go"),
                  callback: action => {
                    if (action === 'confirm') {
                      window.location.href = redirect.endpoint + '/signin'
                    }
                  }
                });
              } else {
                this.$router
                  .push({
                    path: this.redirect || "/",
                    query: this.otherQuery
                  })
                  .catch(() => { });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    handleCommand(command) {
      if (command === 'kr') {
        window.location.href = 'https://console.fplusai.com/signin'
      } else {
        window.location.href = 'https://console.fplusai.com.cn/signin'
      }
    }
  },
  computed: {
    siteIntl() {
      return process.env.VUE_APP_ENV && process.env.VUE_APP_ENV.endsWith(".kr")
    }
  }
};
</script>

<style lang="scss" scoped>
.signin {
  text-align: center;

  .signin-card {
    margin-bottom: 10px;

    .signin-title {
      text-align: left;
    }

    .signin-signup {
      margin: 0 -10px 10px;
    }

    .signin-info {
      margin-right: 5px;
      vertical-align: middle;
    }

    .find-password {
      text-align: right;
      margin: -10px 0 0;
    }
  }

  .signin-content {
    min-height: calc(100vh - 50px);
  }

  .signin-logo {
    margin: 45px 0 30px;
  }

  .signin-button {
    width: 100%;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 14px;
  }
}
</style>